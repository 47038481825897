import { css } from '@emotion/core'
import { colors, fonts, sizes, padding } from './variables'

export default css`
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font: 112.5%/1.45em ${fonts.primary};
    box-sizing: border-box;
    overflow-y: scroll;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    // background: ${colors.body};
    background: black;
    color: ${colors.primary} !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: hsla(0, 0%, 0%, 0.8);
    font-family: ${fonts.primary};
    font-weight: normal;
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  }

  h1, h2, h3, h4, h5, h6, p {
    max-width: ${sizes.constrainWidth};
    padding: ${padding.headings};
    margin: auto;
  }

  @media only screen and (max-width: 480px) {
    html {
      font-size: 100%;
    }
  }
`

export const colors = {
  primary: `#CFD2D3`,
  secondary: `#4A5055`,
  background: `#615495`,
  body: `#1B1630`,
  link: `#0083FF`,
  hover: `#D74E09`,
}

export const fonts = {
  primary: `Gibson, Pulp Display, Helvetica Neue, Helvetica, Liberation Sans, Arial, sans-serif`,
  secondary: `Barlow, Suprema, Helvetica Neue Extended, Verdana, Geneva, sans-serif`,
}

export const shadows = {
  low: `0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)`,
  high: `0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)`,
}

export const padding = {
  gutters: `2rem 3rem`,
  headings: `1rem 3rem`
}

export const sizes = {
  constrainWidth: `1400px`,
  constrainWidthWider: `1800px`,
  headerHeight: `4rem`,
  headerHeightMobile: `4rem`,
}

export const screenWidths = {
  desktopLarge: `2560px`,
  desktop: `1366px`,
  laptop: `1024px`,
  netbook: `900px`,
  tablet: `768px`,
  tabletSmall: `500px`,
  phoneLarge: `411px`,
  phoneSmall: `359px`
}

export const breakpoints = {
  desktopLarge: `min-width: ${screenWidths.desktopLarge}`,
  desktop: `min-width: ${screenWidths.desktop}`,
  laptop: `min-width: ${screenWidths.laptop}`,
  netbook: `min-width: ${screenWidths.netbook}`,
  tablet: `min-width: ${screenWidths.tablet}`,
  tabletSmall: `min-width: ${screenWidths.tabletSmall}`,
  phoneLarge: `min-width: ${screenWidths.phoneLarge}`,
  phoneSmall: `min-width: ${screenWidths.phoneSmall}`
}

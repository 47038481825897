import React from 'react'
import { css, Global } from '@emotion/core'
import styled from '@emotion/styled'
import globalStyles from '../styles'

export default function Layout({ children }) {
  const loc = typeof window !== 'undefined' ? window.location.href : ''
  const isForm = Boolean(loc.substring(loc.lastIndexOf('/') + 1))

  return (
    <>
      <Global styles={globalStyles} />
      <Div>
        {isForm && <div className='back'><a href='/'>{'< Forms'}</a></div>}
        <main>{children}</main>
      </Div>
    </>
  )
}

const Div = styled.div(props => {
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    main {
      flex: 1;
      width: 100%;
      margin: 1rem auto;
      h1 {
        text-align: center;
      }
    }
    .back {
      position: fixed;
      top: 0.25rem;
      left: 0.5rem;
      font-size: 0.75rem;
      a {
        text-decoration: none;
        color: white;
      }
  }
  `
})
